<template>

 <div class="col-12"> 

      <div class="table-responsive">
        <table class="table table-striped table-sm">
          <thead>
            <tr>
              <th>インターフェース</th>
              <th>ファイル</th>
               <th>メソッド</th>
              <th>実行ユーザ *APIの場合</th>
              <th>アクセス可能ユーザ</th>
            </tr>
          </thead>
          <tbody>
              <tr>
              <td> <select class="form-select" v-model="apifile.interface" >
              <option value="UI">UI</option>
              <option value="API" checked>API</option>
              </select>
                </td>
              <td><input class="form" type="file" @change="onFileUploaded" /> </td>
              <td>
              <select class="form-select" v-model="apifile.method" >
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              </select>
              </td>
              <td>
                <select class="form-select" v-model="apifile.execution_user" >
                <option v-for="user in users" v-bind:value="{ name :user.name , kid : user.kid} " v-bind:key="user.kid">
                    {{ user.name }}
                </option>
                </select>
              </td>
              <td> 
                <select class="form-select" v-model="apifile.users"  multiple>
                 <option v-for="user in users" v-bind:value="{ name :user.name , kid : user.kid}" v-bind:key="user.kid">
                    {{ user.name }}
                </option>
               
                </select>

              </td>
              </tr>
          </tbody>
        
        </table>
              <button class="btn btn-dark" v-on:click="Save" > 登録 </button>
        <hr>
     <h2>API一覧</h2>

<table class="table table-striped table-sm">
          <thead>
            <tr>
              <th>URLパス</th>
               <th>メソッド</th>
              <th>実行ユーザ</th>
              <th>アクセス可能ユーザ</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
                 <tr v-for="file in files" v-bind:key="file.name">
              <td>
               {{ (file.interface==="API")? "customapi/":"/app/custom/ui/"  }}{{ file.name.replace(".ipynb","") }}
              </td>
              <td>
               {{ file.file.method }}
              </td>
              <td>
               {{ file.file.execution_user.name }}
              </td>
              <td> 
              <span  v-for="u in file.file.users " v-bind:key="u.name" >
               {{ u.name }} <br>
               </span>
              </td>
              <td>
              <button class="btn btn-dark" v-on:click="Download(file.name)" > ダウンロード </button>
              </td>
              </tr>
          </tbody>
        </table>
        
        
      </div>
</div>
</template>

<script>

import { faEye , faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { defineComponent } from "vue";

import axios from 'axios';

export default defineComponent({
  name: "CustomAPIUploader",
 // components:  {  } ,
  data : function() {return { 
  isSharedKeyChecked : false ,
  users: [] ,
  files : [] ,
  apifile : {},
  apifilename : "",
  } },
  computed: {
  },
  mounted : async function(){
    try{
    const data = await  axios.get("/customapi/files" );
    this.files = data .data.files;
       axios.get("/api/v2/auth/users" ,
        { withCredentials: true }
        )
       .then( function(json) {
           if( json.data){
               const users = [] ; 
               for (var i = 0; i < json.data.users.length ; i++ ){
                   
                   let user = json.data.users[i];
                   if ( user.kid ){
                       users.push(user);
                   }
               }
                this.users  = users;
                console.log(this.users);
                return true;
            }
            return false; 
      }.bind(this));
    } catch (e){console.log(e);}

   },
  methods: {
      Download : async function(filename){
        const link = document.createElement('a')
        link.download =filename;
        link.href = "/customapi/file/"+filename;
        link.click();
      },
      Save : async function(){
        const data = await  axios.post("/customapi/file",{filename: this.apifilename, data : this.apifile} );
        if(data.data.updated){
            window.location.href = window.location.href.split('/').join('/'); 
        }
      },
       onFileUploaded : async function( evt ){
          
            let files = evt.target.files; // FileList object

    // use the 1st file from the list
    
    let reader = new FileReader();
    console.log(files[0]);
    this.apifilename = files[0].name;
    reader.readAsText(files[0]);
    // Closure to capture the file information.
    reader.onload = ((e)=> {
          
         this.apifile = JSON.parse(e.target.result);
      console.log( this.apifile);
      });
     
       }
    }
});

</script>
.container {
  width: 200px;
  margin: 30px;
}

.input {
  width: 100%;
  margin: 0px;
  padding-right: 5px;
}

.eye::after {
  font-family: 'FontAwesome';
  content: "\f06e";
}

.eye-slash::after {
  font-family: 'FontAwesome';
  content: "\f070";
}

.input-icon {
  position: relative;
  float: right;
  margin-top: -25px;
}